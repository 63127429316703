import React from 'react'
import play from './play.svg'
import './PlayButton.css'

export default ({ onPlay, bordered, offset, color }) => (
    <div
        className={`centered  playContainer  ${
            bordered ? 'playContainer--bordered' : ''
        }`}
        style={{
            marginTop: offset
                ? 'calc(var(--vh) * ' + offset / 2 + ' * -1)'
                : undefined
        }}
    >
        <img
            className="play"
            src={play}
            alt="Play video"
            onClick={e => onPlay(e)}
            style={{ fill: '#fff' }}
        />
    </div>
)
