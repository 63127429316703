import React, { useState, memo } from 'react'
import Loader from '../Loader'

export default memo(({ url, title, fit, color }) => {
    const [loaded, setLoaded] = useState(false)

    return (
        <>
            {!loaded && <Loader color={color} />}
            <img
                className="image"
                src={url}
                onLoad={() => setLoaded(true)}
                style={{
                    opacity: loaded ? 1 : 0,
                    objectFit: fit ? 'contain' : 'cover'
                }}
                alt={title}
            />
        </>
    )
})
