export default history => () => {
    if (history.length < 3 || document.referrer !== '') {
        if(document.location.pathname.lastIndexOf('/info') === document.location.pathname.length - 5) {
            history.replace(document.location.pathname.substr(0, -4) + '1')
            return
        }

        history.replace('/')
    } else {
        history.goBack()
    }
}
