import React, { useEffect, useState, useCallback, memo } from 'react'
import { Route } from 'react-router-dom'
import Loader from '../Loader'
import Overview from '../Overview'
import About from '../About'
import Info from '../Info'
import Player from '../Player'
import { fetchProjects, fetchAbout, fetchFooter } from '../../api'
import {
    MEDIA_TYPE_VIDEO,
    MEDIA_TYPE_ANIMATION,
    MEDIA_TYPE_IMAGE
} from '../../constants'
import { resizeEnd, slugify, withGA } from '../../effects'

const TrackedOverview = withGA(Overview)
const TrackedAbout = withGA(About)
const TrackedInfo = withGA(Info)
const TrackedPlayer = withGA(Player)

export default memo(() => {
    const [projects, setProjects] = useState()
    const [about, setAbout] = useState()
    const [footer, setFooter] = useState()

    const isPortrait = () => window.innerHeight > window.innerWidth
    const [portrait, setPortrait] = useState(isPortrait())

    const isAnimation = src => src.lastIndexOf('.json') === src.length - 5
    const isVideo = src => src.lastIndexOf('.mp4') === src.length - 4

    const loadProjects = useCallback(async () => {
        try {
            const ps = await fetchProjects()
            const pr = await Promise.all(
                ps.map(async p => ({
                    ...p,
                    ...p.fields,
                    id: slugify(p.fields.title),
                    ...ps.reduce((acc, cur, index) => {
                        if (
                            slugify(p.fields.title) ===
                            slugify(cur.fields.title)
                        ) {
                            const previousIndex =
                                index === 0 ? ps.length - 1 : index - 1
                            const nextIndex =
                                index === ps.length - 1 ? 0 : index + 1
                            acc = {
                                previousId: slugify(
                                    ps[previousIndex].fields.title
                                ),
                                nextId: slugify(ps[nextIndex].fields.title)
                            }
                        }

                        return acc
                    }, {}),
                    bgColor: p.fields.bgColor && '#' + p.fields.bgColor,
                    textColor: p.fields.textColor && '#' + p.fields.textColor,
                    medias: await Promise.all(
                        p.fields.files.map(async media => {
                            const url = media.fields.file.fields.file.url

                            try {
                                return {
                                    ...media.fields,
                                    ...media.fields.file.fields,
                                    id: media.fields.file.sys.id,
                                    bgColor:
                                        media.fields.bgColor &&
                                        '#' + media.fields.bgColor,
                                    textColor:
                                        media.fields.textColor &&
                                        '#' + media.fields.textColor,
                                    type: isVideo(url)
                                        ? MEDIA_TYPE_VIDEO
                                        : isAnimation(url)
                                        ? MEDIA_TYPE_ANIMATION
                                        : MEDIA_TYPE_IMAGE
                                }
                            } catch (e) {
                                console.error(e)
                            }
                        })
                    )
                }))
            )

            setProjects(pr)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if (projects) {
            return
        }

        loadProjects()
    }, [projects, loadProjects])

    const loadAbout = useCallback(async () => {
        try {
            const a = await fetchAbout()

            setAbout(a)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if (about) {
            return
        }

        loadAbout()
    }, [about, loadAbout])

    const loadFooter = useCallback(async () => {
        try {
            const f = await fetchFooter()

            setFooter(f)
        } catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if (footer) {
            return
        }

        loadFooter()
    }, [footer, loadFooter])

    const defineVh = () => {
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    const onResize = () => {
        const doResize = () => {
            defineVh()
            setPortrait(isPortrait())
        }

        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            setTimeout(() => {
                doResize()
                if (document.location.pathname.length > 1) {
                    window.scrollTo(0, 0)
                }
            }, 500)
        } else {
            doResize()
        }
    }

    useEffect(() => {
        const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i)
        let off

        if (isIOS) {
            window.addEventListener('orientationchange', onResize, false)
        } else {
            off = resizeEnd(onResize)
        }

        return () => {
            window.removeEventListener('orientationchange', onResize)
            if (off) {
                off()
            }
        }
    })

    useEffect(() => {
        defineVh()
    }, [])

    if (!projects) {
        return <Loader />
    }

    return (
        <>
            <Route
                exact
                path="/"
                render={props => (
                    <TrackedOverview
                        {...props}
                        projects={projects}
                        footer={footer}
                    />
                )}
            />
            <Route
                exact
                path="/about"
                render={props => <TrackedAbout {...props} content={about} />}
            />
            <Route
                exact
                path="/project/:project/info"
                render={props => <TrackedInfo {...props} projects={projects} />}
            />
            <Route
                path="/project/:project/:slide?"
                render={props => (
                    <TrackedPlayer
                        {...props}
                        portrait={portrait}
                        projects={projects}
                    />
                )}
            />
        </>
    )
})
