import React, { useEffect, useCallback } from 'react'
import { close } from '../../effects'
import './About.css'

export default ({ history, content }) => {
    const onClose = useCallback(close(history), [history])

    useEffect(() => {
        window.scrollTo(0, 0)

        const bgColor = document.body.style.backgroundColor
        document.body.style.backgroundColor = '#1a1a1a'

        window.onkeydown = e => {
            if (e.which === 27) {
                // esc
                onClose()
            }
        }

        return () => {
            document.body.style.backgroundColor = bgColor
            window.onkeydown = null
        }
    }, [onClose])

    return (
        <aside className="about">
            <header className="about__header">
                <div className="about__title" onClick={() => onClose()}>
                    <span>
                        A<span className="logoDot"> · </span>Iten
                    </span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 15.7 8.9"
                        width="15.7"
                        height="8.9"
                    >
                        <path
                            fill="inherit"
                            d="M7.6 0L0 7.1l1.7 1.8 6.2-5.7L14 8.9l1.7-1.8L8.1 0z"
                        />
                    </svg>
                </div>
            </header>
            <div
                className="about__content  contentMargin"
                dangerouslySetInnerHTML={{ __html: content }}
            ></div>
        </aside>
    )
}
