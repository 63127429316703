export default onResizeEnd => {
    let timer
    const onResize = () => {
        clearTimeout(timer)
        timer = setTimeout(() => onResizeEnd(), 200)
    }

    window.addEventListener('resize', onResize, false)
    return () => window.removeEventListener('resize', onResize)
}
