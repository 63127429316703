import React, { memo } from 'react'
import Lottie from '../Lottie'

export default memo(({ data, title, fit, loop, playing }) => (
    <div className="image">
        <Lottie
            playing={playing}
            title={title}
            options={{
                loop,
                animationData: data,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid ' + (fit ? 'meet' : 'slice')
                }
            }}
        />
    </div>
))
