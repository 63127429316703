import React, { useEffect, memo } from 'react'
import { Link } from 'react-router-dom'
import Appbar from '../Appbar'
import Footer from '../Footer'
import './Overview.css'
import onScrollEnd from '../../effects/scrollEnd'

export default memo(({ projects, footer }) => {
    const updateScroll = () => {
        sessionStorage.setItem('overview.scrollY', window.scrollY)
    }

    useEffect(() => onScrollEnd(updateScroll))

    useEffect(() => {
        const position = sessionStorage.getItem('overview.scrollY') || 0
        window.scrollTo(0, position)

        const bgColor = document.body.style.backgroundColor
        document.body.style.backgroundColor = 'var(--bg-color)'

        return () => {
            document.body.style.backgroundColor = bgColor
        }
    }, [])

    return (
        <div className="overviewContainer">
            <Appbar />
            <div className="scrollout">Graphic Design</div>
            <ul className="overview  appbarMargin">
                {projects.map(project => {
                    const firstImage = project.thumbnail
                        ? project.thumbnail.fields
                        : project.medias.find(
                              m => m.file.url.indexOf('.jpg') > -1
                          )

                    const thumbnail = firstImage
                        ? firstImage.file.url
                        : 'http://placehold.it/500x500'
                    return (
                        <li key={project.id} className="overview__image">
                            <Link to={`/project/${project.id}/1`}>
                                <img src={thumbnail} alt={project.title} />
                            </Link>
                        </li>
                    )
                })}
            </ul>
            <Footer content={footer} />
        </div>
    )
})
