import React, { memo, useEffect, createRef, useCallback, useRef } from 'react'

export default memo(({ url, fit, loop, playing }) => {
    const video = createRef()
    const isStarted = useRef(false)
    const isLoaded = useRef(false)

    const play = useCallback(async v => {
        await v.play()
        isStarted.current = true
    }, [])

    const stop = useCallback(v => {
        if (!isStarted.current) return

        requestAnimationFrame(() => {
            if (v.currentTime !== 0 && !v.ended) {
                v.currentTime = 0
            }
            if (!v.paused && !v.ended) {
                v.pause()
            }
        })
    }, [])

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const v = video.current
        if (!v) return

        v.load()
        isLoaded.current = true
    }, [])

    useEffect(() => {
        const v = video.current
        if (!v) return

        if (!isLoaded.current) return

        if (playing) {
            play(v)
        } else {
            stop(v)
        }
    }, [playing, video, play, stop, url, isLoaded])

    return (
        <>
            <video
                className="image"
                playsInline
                muted
                ref={video}
                loop={loop}
                preload="auto"
                style={{
                    objectFit: fit ? 'contain' : 'cover'
                }}
            >
                <source src={url} type="video/mp4" />
            </video>
        </>
    )
})
