export default onScrollEnd => {
    let timer
    const onScroll = () => {
        window.clearTimeout(timer)
        timer = setTimeout(() => onScrollEnd(), 200)
    }

    window.addEventListener('scroll', onScroll, false)
    return () => window.removeEventListener('scroll', onScroll)
}
