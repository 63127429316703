import React, { memo, createRef, useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import './Lottie.css'

export default memo(({ options, title, playing, style }) => {
    const el = createRef()
    const animation = useRef()
    const { loop, autoplay, animationData, rendererSettings } = options

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        animation.current = lottie.loadAnimation({
            container: el.current,
            renderer: 'svg',
            loop: loop === true,
            autoplay: autoplay === true,
            animationData,
            rendererSettings,
            ...options
        })

        return () => {
            if (!animation.current) {
                return
            }
            animation.current.destroy()
            animation.current = null
        }
    }, [])

    useEffect(() => {
        if (!animation.current) return

        if (playing) {
            animation.current.play()
        } else {
            animation.current.stop()
        }
    })

    return (
        <div className="lottie" ref={el} style={{ ...style }} title={title} />
    )
})
