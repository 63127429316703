import React, { memo } from 'react'
import Header from '../Header'
import Slide from '../Slide'
import Dots from '../Dots'
import Loader from '../Loader'
import './Project.css'

export default memo(
    ({
        slide,
        portrait,
        project,
        onToggle,
        onInfoToggle,
        onClose,
        swipeHandlers
    }) => {
        const media = project.medias[slide]
        const fg = media.textColor || project.textColor

        return (
            <div
                className="projectContainer"
                style={{
                    backgroundColor: project.bgColor
                }}
            >
                <Header
                    title={project.title}
                    textColor={fg}
                    onInfoToggle={() => onInfoToggle()}
                    onClose={() => onClose()}
                    hasDescription={!!project.description}
                />
                {project.isLoaded ? (
                    <ul
                        className="canvas"
                        onClick={e => onToggle(e)}
                        {...swipeHandlers}
                    >
                        {project.medias.map((media, index) => (
                            <Slide
                                media={media}
                                active={index === slide}
                                preload={slide === index || slide + 1 === index}
                                portrait={portrait}
                                project={project}
                                key={media.id}
                            />
                        ))}
                    </ul>
                ) : (
                    <Loader color={fg} />
                )}
                <footer className="projectFooter">
                    <span />
                    <span
                        className="caption"
                        style={{
                            color: fg
                        }}
                    >
                        {media.caption || ' '}
                    </span>
                    <Dots
                        count={project.medias.length}
                        active={slide}
                        color={fg}
                    />
                </footer>
            </div>
        )
    }
)
