import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './Footer.css'

export default ({ content }) => {
    return (
        <footer className="footer">
            <span>{documentToReactComponents(content)}</span>
        </footer>
    )
}
