import React from 'react'
import './Header.css'

export default ({
    title,
    textColor,
    onInfoToggle,
    onClose,
    hasDescription
}) => {
    return (
        <header
            className="header"
            style={{
                color: textColor
            }}
        >
            <span
                className="header-title"
                style={{
                    cursor: hasDescription ? 'pointer' : 'default',
                    fill: textColor
                }}
                onClick={() => (hasDescription ? onInfoToggle() : null)}
            >
                {title || ''}{' '}
                {hasDescription && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 15.7 8.9"
                    >
                        <path
                            fill="inherit"
                            d="M8.1 8.9l7.6-7.1L14 0 7.9 5.7 1.7 0 0 1.8l7.6 7.1z"
                        />
                    </svg>
                )}
            </span>
            <div
                className="header-close"
                onClick={() => onClose()}
                style={{ fill: textColor }}
            >
                <svg
                    version="1.2"
                    baseProfile="tiny"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25.9 25.9"
                >
                    <path
                        fill="inherit"
                        d="M25.8 2.4L23.4 0 12.9 10.4 2.4 0 0 2.4l10.4 10.5L0 23.4l2.4 2.4 10.5-10.4 10.5 10.4 2.4-2.4-10.4-10.5z"
                    />
                </svg>
            </div>
        </header>
    )
}
