import React from 'react'
import './Dots.css'

export default ({ count, active, color }) => {
    if (count < 2) {
        return null
    }

    return (
        <span className="dots" style={{ color }}>
            {`${active + 1} / ${count}`}
        </span>
    )
}
