import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-156694926-1')

export default (WrappedComponent, options = {}) => {
    const pageView = page => {
        ReactGA.set({
            page,
            ...options
        })
        ReactGA.pageview(page)
    }

    return props => {
        useEffect(() => pageView(props.location.pathname), [
            props.location.pathname
        ])

        return <WrappedComponent {...props} />
    }
}
