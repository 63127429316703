import { Comlink } from 'comlinkjs/comlink.es6.js'
import client from './client'

const proxy = Comlink.proxy(new Worker('/comlink-fetch.worker.js'))
export const fetchUrl = async url => {
    const request = await new proxy.Fetch()
    return await request.getJson(url)
}

const getEntries = async content_type =>
    await client.getEntries({
        content_type,
        resolveLinks: true,
        limit: 1000
    })

const fetchSlides = async () => {
    return (await getEntries('slide')).items
}

export const fetchProjects = async () => {
    const slide = await fetchSlides()
    const projects = await getEntries('projects')

    return projects.items[0].fields.project.map(project => ({
        ...project,
        fields: {
            ...project.fields,
            files: project.fields.files.map(file => ({
                ...slide.find(slide => slide.sys.id === file.sys.id)
            }))
        }
    }))
}

export const fetchAbout = async () => {
    return (await getEntries('pages')).items.find(
        p => p.fields.title === 'Info'
    ).fields.content2
}

export const fetchFooter = async () => {
    return (await getEntries('pages')).items.find(
        p => p.fields.title === 'Footer'
    ).fields.content
}

export default client
