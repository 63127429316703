import React, { memo } from 'react'
import { Video, MutedVideo } from '../Video'
import Animation from '../Animation'
import Image from '../Image'
import { MEDIA_TYPE_VIDEO, MEDIA_TYPE_ANIMATION } from '../../constants'

export default memo(({ media, portrait, project, active, preload }) => {
    const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i)
    const bordered =
        media.bordered !== undefined
            ? media.bordered
            : project.bordered === true
    const fit =
        bordered || (media.fit !== undefined ? media.fit : project.fit === true)
    const bgColor = media.bgColor || project.bgColor
    const textColor = media.textColor || project.textColor
    const height = portrait ? media.maxHeight || project.maxHeight || 100 : 100

    const renderMedia = (media, isActive) => {
        switch (media.type) {
            case MEDIA_TYPE_VIDEO:
                if (media.poster) {
                    return (
                        <Video
                            url={media.file.url}
                            fit={fit}
                            loop={media.loop === true}
                            active={isActive}
                            bordered={bordered}
                            offset={100 - height}
                            color={textColor}
                            poster={media.poster.fields.file.url}
                        />
                    )
                } else {
                    return (
                        <MutedVideo
                            url={media.file.url}
                            fit={fit}
                            loop={media.loop === true}
                            playing={isActive}
                        />
                    )
                }
            case MEDIA_TYPE_ANIMATION:
                return (
                    <Animation
                        data={media.data}
                        fit={fit}
                        loop={media.loop === true}
                        title={media.title || project.title}
                        playing={isActive}
                    />
                )
            default:
                return (
                    <Image
                        url={media.file.url}
                        title={media.title || project.title}
                        fit={fit}
                        color={textColor}
                    />
                )
        }
    }

    return (
        <li
            className="slide"
            style={{
                backgroundColor: bgColor,
                zIndex: active ? 2 : 1,
                visibility: preload ? 'visible' : 'hidden'
            }}
        >
            {!isIOS && <div className="slide--left" />}
            <div
                className="imageContainer"
                style={{
                    margin: bordered ? '2.8em 1.5em' : 0,
                    height:
                        'calc(var(--vh, 1vh) * ' +
                        height +
                        ' - ' +
                        (bordered ? 5.6 : 0) +
                        'em)'
                }}
            >
                {renderMedia(media, active)}
            </div>
            {!isIOS && <div className="slide--right" />}
        </li>
    )
})
