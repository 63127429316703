import React, { memo, useEffect, useState, useCallback, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import Project from '../Project'
import { close, slugify } from '../../effects'
import { fetchUrl } from '../../api'

export default memo(({ match, history, portrait, projects }) => {
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setSlide(getSlide(activeSlide.current + 1)),
        onSwipedRight: () => setSlide(getSlide(activeSlide.current - 1)),
        delta: 10,
        preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: true,
        rotationAngle: 0
    })

    const getActiveSlide = useCallback(
        pr =>
            Math.max(
                0,
                Math.min(
                    (parseInt(match.params.slide, 10) || 1) - 1,
                    pr.medias.length - 1
                )
            ),
        [match.params.slide]
    )

    const getProjectById = useCallback(
        id => projects.find(p => slugify(p.fields.title) === id),
        [projects]
    )

    const getActiveProject = useCallback(
        () => getProjectById(match.params.project),
        [getProjectById, match.params.project]
    )

    const [project, setProject] = useState(getActiveProject)
    const activeSlide = useRef(getActiveSlide(project))

    useEffect(() => {
        const p = getActiveProject()
        if (!p) return

        document.title =
            p.metaTitle || p.title + ' - Andreas Iten Graphic Design'
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                'content',
                p.metaDescription || p.title + ' - Andreas Iten Graphic Design'
            )

        return () => {
            document.title = 'Andreas Iten - Graphic Design'
            document
                .querySelector('meta[name="description"]')
                .setAttribute('content', document.title)
        }
    })

    useEffect(() => {
        document.documentElement.classList.add('noscroll')

        if (
            window.orientation !== 0 &&
            navigator.userAgent.match(/iPhone|iPad|iPod/i)
        ) {
            setTimeout(() => {
                let vh = window.innerHeight * 0.01
                document.documentElement.style.setProperty('--vh', `${vh}px`)
                window.scrollTo(0, 0)
            }, 500)
        }

        return () => {
            document.documentElement.classList.remove('noscroll')
        }
    }, [])

    const onPlayerClose = useCallback(close(history), [history])

    const isAnimation = src => src.lastIndexOf('.json') === src.length - 5

    const preloadProject = useCallback(async pr => {
        setProject({
            ...pr,
            medias: await Promise.all(
                pr.medias.map(async media => {
                    const url = media.file.url
                    return {
                        ...media,
                        data: isAnimation(url) && (await fetchUrl(url))
                    }
                })
            ),
            isLoaded: true
        })
    }, [])

    const fetchProject = useCallback(() => {
        const pr = getActiveProject()

        if (!pr) {
            history.replace('/')
            return
        }

        activeSlide.current = getActiveSlide(pr)
        setProject(pr)
    }, [history, getActiveProject, getActiveSlide])

    useEffect(() => {
        if (project && project.id === match.params.project) {
            if (!project.isLoaded) {
                preloadProject(project)
            }
            return
        }

        fetchProject()
    }, [project, match.params.project, fetchProject, preloadProject])

    const getSlide = useCallback(
        index => Math.max(Math.min(index, project.medias.length), -1),
        [project]
    )

    const setSlide = useCallback(
        index => {
            if (index === -1) {
                // Go to previous project
                history.replace(
                    '/project/' +
                        project.previousId +
                        '/' +
                        getProjectById(project.previousId).medias.length
                )
            } else if (index === project.medias.length) {
                // Go to next project
                history.replace('/project/' + project.nextId + '/1')
            } else {
                activeSlide.current = index
                history.replace('/project/' + project.id + '/' + (index + 1))
            }
        },
        [project, history, getProjectById]
    )

    const onInfoToggle = () => {
        history.push('/project/' + project.id + '/info')
    }

    const onClick = e => {
        e = e.originalEvent || e
        e.stopPropagation()
        e.preventDefault()

        let pageX = e.pageX
        if (pageX === undefined && e.touches && e.touches.length > 0) {
            if (e.touches.length > 1) {
                return // Allow zooming
            }
            pageX = e.touches[0].pageX
        }

        if (pageX === undefined) {
            return
        }

        setSlide(
            getSlide(
                activeSlide.current +
                    (pageX / window.innerWidth >= 0.5 ? 1 : -1)
            )
        )
    }

    useEffect(() => {
        if (!project.isLoaded) return

        window.onkeydown = e => {
            if (e.which === 27) {
                // esc (go back to overview)
                onPlayerClose()
            } else if (e.which === 37) {
                e.preventDefault()
                // left arrow
                setSlide(getSlide(activeSlide.current - 1))
            } else if (e.which === 39) {
                e.preventDefault()
                // right arrow
                setSlide(getSlide(activeSlide.current + 1))
            }
        }

        return () => (window.onkeydown = null)
    }, [setSlide, getSlide, onPlayerClose, project])

    return (
        <>
            <Project
                project={project}
                portrait={portrait}
                slide={activeSlide.current}
                onToggle={e => onClick(e)}
                onInfoToggle={() => onInfoToggle()}
                onClose={() => onPlayerClose()}
                swipeHandlers={swipeHandlers}
            />
        </>
    )
})
