import * as contentful from 'contentful'

export default contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    host:
        process.env.REACT_APP_ENV === 'production'
            ? 'cdn.contentful.com'
            : 'preview.contentful.com'
})
