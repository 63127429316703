import React, { useState, useEffect, createRef, useRef, memo } from 'react'
import PlayButton from './PlayButton'

export default memo(
    ({ url, fit, loop, active, poster, color, bordered, offset }) => {
        const video = createRef()
        const first = useRef(true)
        const [playing, setPlaying] = useState(false)

        const play = e => {
            e = e.originalEvent || e
            e.preventDefault()
            e.stopPropagation()
            const v = video.current
            if (!v) return

            first.current = false
            v.play()
        }

        const stop = v => {
            requestAnimationFrame(() => {
                if (!v.paused && !v.ended) {
                    v.pause()
                }
                if (v.currentTime !== 0) {
                    v.currentTime = 0
                }
            })
        }

        useEffect(() => {
            const v = video.current
            if (!v) return

            if (!active) {
                stop(v)
                if (playing) {
                    setPlaying(false)
                }
                return
            }

            const isPlaying = () => setPlaying(true)
            const ended = () => setPlaying(false)

            v.addEventListener('playing', isPlaying, false)
            v.addEventListener('ended', ended, false)

            return () => {
                v.removeEventListener('playing', isPlaying)
                v.removeEventListener('ended', ended)
            }
        }, [video, active, playing])

        return (
            <>
                {!playing && (
                    <PlayButton
                        onPlay={e => play(e)}
                        bordered={bordered}
                        offset={offset}
                        color={color}
                    />
                )}
                <video
                    className="image"
                    playsInline
                    poster={poster}
                    ref={video}
                    loop={loop}
                    preload="none"
                    style={{
                        objectFit: fit ? 'contain' : 'cover'
                    }}
                >
                    <source
                        src={url}
                        type={`video/${url.substr(url.lastIndexOf('.') + 1)}`}
                    />
                </video>
            </>
        )
    }
)
