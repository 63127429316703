import React, { useCallback, useEffect, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { close, slugify } from '../../effects'
import './Info.css'

export default ({ history, match, projects }) => {
    const onClose = useCallback(close(history), [history])

    const getActiveProject = useCallback(
        () =>
            projects.find(
                p => slugify(p.fields.title) === match.params.project
            ),
        [projects, match.params.project]
    )

    const [project, setProject] = useState(getActiveProject())

    useEffect(() => {
        window.scrollTo(0, 0)

        const bgColor = document.body.style.backgroundColor
        document.body.style.backgroundColor =
            (project && project.bgColor) || '#1a1a1a'

        return () => {
            document.body.style.backgroundColor = bgColor
        }
    }, [project])

    const fetchProject = useCallback(() => {
        const pr = getActiveProject()

        if (!pr) {
            history.replace('/')
            return
        }

        setProject(pr)
    }, [history, getActiveProject])

    useEffect(() => {
        if (project && project.id === match.params.project) {
            return
        }

        fetchProject()
    })

    useEffect(() => {
        document.documentElement.classList.remove('noscroll')

        return () => {
            document.documentElement.classList.add('noscroll')
        }
    }, [])

    return (
        <aside
            className="info"
            style={{
                color: project.textColor || '#000',
                borderColor: project.textColor || '#000',
                backgroundColor: project.bgColor || '#fff'
            }}
        >
            <header
                className="info__header"
                style={{
                    backgroundColor: project.bgColor || '#fff'
                }}
            >
                <div
                    className="info__title"
                    onClick={() => onClose()}
                    style={{
                        cursor: 'pointer',
                        fill: project.textColor || '#000'
                    }}
                >
                    {project.title}{' '}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 15.7 8.9"
                        width="15.7"
                        height="8.9"
                    >
                        <path
                            fill="inherit"
                            d="M7.6 0L0 7.1l1.7 1.8 6.2-5.7L14 8.9l1.7-1.8L8.1 0z"
                        />
                    </svg>
                </div>
            </header>
            <div className="info__content  contentMargin">
                {documentToReactComponents(project.description)}
                <ul
                    className="social"
                    style={{
                        fill: project.textColor
                    }}
                >
                    <li>
                        <a href="mailto:studio.itenandreas@gmail.com">
                            <svg
                                version="1.2"
                                baseProfile="tiny"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 21"
                                width="32"
                                height="21"
                            >
                                <path
                                    fill="inherit"
                                    d="M31.3 0H.8C.4 0 0 .4 0 .8v19.5c0 .4.4.7.8.7h30.5c.4 0 .8-.3.8-.8V.8c-.1-.4-.4-.8-.8-.8zM9.9 10.8l-8.3 8.6v-17l8.3 8.4zm.7.7l2.5 2.5c.1.1.2.1.4.1h5c.1 0 .3-.1.4-.1l2.3-2.3 7.4 7.8H2.8l7.8-8zm7.6 1.7h-4.6L2.1 1.5H30L18.2 13.2zm11.7 6.3L21.8 11l8.7-8.7v17.2h-.6z"
                                />
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://instagram.com/andreasiten_ch"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 23 23"
                                width="23"
                                height="23"
                            >
                                <g fill="inherit">
                                    <path d="M18 23H5c-2.8 0-5-2.2-5-5V5c0-2.8 2.2-5 5-5h13c2.8 0 5 2.2 5 5v13c0 2.8-2.2 5-5 5zM5 1.5C3.1 1.5 1.5 3.1 1.5 5v13c0 1.9 1.6 3.5 3.5 3.5h13c1.9 0 3.5-1.6 3.5-3.5V5c0-1.9-1.6-3.5-3.5-3.5H5z" />
                                    <path d="M11.5 16.2c-2.6 0-4.7-2.1-4.7-4.7s2.1-4.7 4.7-4.7 4.7 2.1 4.7 4.7-2.1 4.7-4.7 4.7zm0-8.4c-2.1 0-3.7 1.7-3.7 3.7s1.7 3.7 3.7 3.7 3.7-1.7 3.7-3.7-1.6-3.7-3.7-3.7zM16.9 4.6c.7 0 1.4.6 1.4 1.4 0 .7-.6 1.4-1.4 1.4s-1.3-.7-1.3-1.4c0-.8.6-1.4 1.3-1.4z" />
                                </g>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    )
}
