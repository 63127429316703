import React from 'react'
import { Link } from 'react-router-dom'
import './Appbar.css'

export default () => (
    <header className="appbar">
        <div className="appbar-title">
            <Link to="/about">
                <span>
                    A<span className="logoDot"> · </span>Iten
                </span>
            </Link>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.7 8.9">
                <path
                    fill="inherit"
                    d="M8.1 8.9l7.6-7.1L14 0 7.9 5.7 1.7 0 0 1.8l7.6 7.1z"
                />
            </svg>
        </div>
    </header>
)
